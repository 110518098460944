import * as React from "react";
import HeroAlt from "containers/HeroAlt";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import Tap from "@tapfiliate/tapfiliate-js";

const AffiliateSignupPage = ({ location }) => {

    React.useEffect(() => {
        Tap.detect({}, (error, result) => {
            console.log("Tap initialized and callback called");
        }, true);
    }, []);
    return (
        <Layout location={location.pathname}>
            <Seo title="Become an Affiliate" />
            <HeroAlt
                title="ERT Credit Affiliate Sign up"
                text="ERT Credit Affiliates earn thousands of dollars in affiliate referral fees per month!"
            />

        </Layout>
    );
};

export default AffiliateSignupPage;